.container {
    flex: 1;
    padding: 24px;
    display: flex;
    justify-content: center;
    background: white;
}

.subContainer {
    width: 100%;
    max-width: 570px;
    flex-direction: column;
    margin-top: 50px
}

.title {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-bottom: 0px;
}

.subtitle {
    font-size: 15px;
    color: #525c66;
    font-weight: normal
}

.resendFirst {
    font-size: 15px;
    color: #525c66
}

.codeInput {
    margin: 4px;
    width: 35px;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    height: 50px;
    background-color: rgba(32, 51, 69, 0.04);
    color: black;
    border: 1px solid black
}