.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 25px;
    box-sizing: border-box;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.inputButton {
    height: 50px;
    width: 280px;
    border-radius: 7px;
    background: #0666EB;
    font-weight: 700;
    color: white;
}

.container2 {
    max-width: 555px;
    box-sizing: border-box;
}

.titleContainer {
    margin-top: 50px;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.title {
    font-size: 20px;
    font-weight: normal;
    text-align: left;
    color: #000;
    margin-bottom: 0px;
    max-width: "100%";
}

.subTitle {
    text-align: left;
    color: #525C66;
    font-size: 14px;
    margin-top: 20px;
    font-weight: normal;
    max-width: "100%";
}

.input {
    opacity: 0;
}

.cellsContainer {
    margin-top: 10px;
    padding: 13px;
    display: flex;
    flex-direction: column;
    background: white;
    width: 100%;
    max-width: 535px;
    border-radius: 13px;
    box-sizing: border-box
}

.cellContainer {
    display: flex;
    padding: 30px 15px;
    align-items: center;
    justify-content: flex-start;
    text-transform: none;
    border: 1px solid #DFE2E6;
    border-radius: 13px;
    margin-bottom: 20px;
    cursor: pointer;
    background: white;
}

.imgContainer {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    min-height: 55px;
    min-width: 55px;
    display: flex;
    justify-content: center;
    align-items: center
}

.cellText {
    margin-left: 15px;
    font-size: 16px;
    color: #15171a
}